<template>
  <section class="game">

    <aside class="title">
      <span class="element-tag">&lt;WheelChairGuy Game Section&gt;</span>
      <h3 class="white-selection">Wheelchair Guy</h3>
      <div class="tags">
        <span class="tag">
          <strong class="blue">Beta Version :D</strong>
          I just run my first game project with Phaser Here cuz I think it's cool to play games in browsers.
        </span>
        <span class="tag">
          <strong class="blue">Movement Help</strong>
          Desktop : Move around with arrow keys and use down key to use some in-game objects.
          <br>
          Mobile : Move around with touching left and right zone of the game, while moving, you can touch other side to jump. touch the objects to get interactive with them.
        </span>
        <span class="tag">
          <strong class="blue">Game Help</strong>
          Go active the lever and let the water pushes the key from the blue pipe, use the key to open the chest and check console to see the secret message. }: )
        </span>
      </div>
    </aside>

    <div class="desc">

      <div id="game-section-1"></div>
      <button v-if="!gameFlag" class="start-btn" @click="startGame">Start Wheelchair Guy Game</button>
      <button v-else class="stop-btn" @click="stopGame">Stop Game</button>

      <span class="element-tag">&lt;&#8725;WheelChairGuy Game Section&gt;</span>
    </div>
  </section>
</template>

<script>
import {ref} from "vue";
import {launch, remove} from "../game/wheelchairGuy.js";

export default {
  name: "IndexGame",
  setup(){

    let gameFlag = ref(false);

    function startGame(){
      if(gameFlag.value){
        remove();
      }
      gameFlag.value = true;
      launch("game-section-1");
    }

    function stopGame(){
      gameFlag.value = false;
      remove();
      // document.getElementById("game-section").innerHTML = "";
    }

    return {gameFlag, startGame, stopGame}

  }
}
</script>

<style lang="scss" scoped>
@media screen and (min-width: 1200px) {
  section.game {
    display: flex;
    justify-content: center;
    flex-grow: 1;
    width: auto;
    min-height: 100vh;
    height: auto;
    padding: 5% 10%;
    background-color: rgba(229, 229, 229, 0.3);

    aside.title {
      display: flex;
      position: relative;
      align-self: flex-start;
      flex-direction: column;
      width: 20%;
      height: auto;
      padding: 10px;
      background-color: rgba(0, 0, 0, 0.5);
      box-shadow: -4px -4px 0px 0px #e5e5e5;

      div.tags {
        display: flex;
        flex-flow: row wrap;
        width: auto;
        height: auto;
        padding: 10px 0;

        span.tag {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          width: auto;
          height: auto;
          line-height: 20px;
          margin-bottom: 10px;
          padding: 10px 15px;
          font-size: 0.85rem;
          font-family: 'Lexend Deca';
          letter-spacing: 0.06rem;
          border: 1px solid #e5e5e5;
          box-shadow: -2px -2px 0px 0px #e5e5e5;

          strong {
            display: block;
            width: auto;
            height: auto;
            margin: 0 0 8px -6px;
            padding: 0 10px 3px 10px;
            font-size: 9px;
            letter-spacing: 0.05rem;
            font-weight: normal;
            border-radius: 25px;
          }

          strong.blue {
            background-color: #2f6ead;
          }

          strong.orange {
            background-color: #bf7639;
          }

        }

        span.tag
        span.tag::-moz-selection {
          background-color: #e5e5e5;
        }

        span.tag::selection {
          background-color: #e5e5e5;
        }

      }

      span.element-tag {
        display: block;
        position: absolute;
        width: auto;
        height: auto;
        line-height: 14px;
        top: -22px;
        left: -4px;
        font-family: 'Lexend Deca';
        font-size: 0.9rem;
        color: #e5e5e5;
        background-color: #e5e5e5;
      }

      span.element-tag::selection {
        background-color: black;
      }

      span.element-tag::-moz-selection {
        background-color: black;
      }

      h3 {
        display: block;
        width: 100%;
        height: auto;
        line-height: 40px;
        font-family: 'Turret Road';
        font-size: 1.5rem;
        text-align: center;
        font-weight: bolder;
        letter-spacing: 0.45rem;
      }

      img {
        width: 100%;
        height: auto;
        padding: 10px 0;
        border-radius: 50%;
      }

    }

    div.desc {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      position: relative;
      flex-direction: column;
      width: calc(80% - 10px);
      height: auto;
      margin-left: 10px;
      padding: 20px;
      background-color: rgba(0, 0, 0, 0.5);
      box-shadow: 4px 4px 0px 0px #e5e5e5;

      button.start-btn, button.stop-btn{
        display: block;
        cursor: pointer;
        width: 50%;
        height: auto;
        line-height: 40px;
        margin: 10px 0 2px 0;
        text-align: center;
        transition: 0s;
        -webkit-transition: 0s;
        -moz-transition: 0s;
        -o-transition: 0s;

        &:hover {
          margin: 12px 0 0 2px;
          transition: 0s;
          -webkit-transition: 0s;
          -moz-transition: 0s;
          -o-transition: 0s;
        }

      }
      button.start-btn{
        border: 1px solid #32a852;
        &:hover {
          box-shadow: -2px -2px 0 0 #32a852;
        }
      }

      button.stop-btn{
        border: 1px solid #bf5261;
        &:hover {
          box-shadow: -2px -2px 0 0 #bf5261;
        }
      }

      > div#game-section-1 {
        display: flex;
        flex-grow: 1;
        width: 100%;
        height: auto;
      }

      span.element-tag {
        display: block;
        position: absolute;
        width: auto;
        height: auto;
        line-height: 14px;
        bottom: -22px;
        right: -4px;
        font-family: 'Lexend Deca';
        font-size: 0.9rem;
        color: #e5e5e5;
        background-color: #e5e5e5;
      }

      span.element-tag::selection {
        background-color: black;
      }

      span.element-tag::-moz-selection {
        background-color: black;
      }

    }

  }
}
@media screen and (min-width: 800px) and (max-width: 1199px) {
  section.game {
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    flex-grow: 1;
    width: auto;
    min-height: 100vh;
    height: auto;
    padding: 10% 20px;
    background-color: rgba(229, 229, 229, 0.3);

    aside.title {
      display: flex;
      flex-flow: column wrap;
      justify-content: flex-start;
      align-items: center;
      position: relative;
      width: 100%;
      height: auto;
      padding: 10px;
      padding-bottom: 0;
      background-color: rgba(0, 0, 0, 0.5);
      box-shadow: -4px -4px 0px 0px #e5e5e5;

      div.tags {
        display: flex;
        max-width: 100%;
        width: auto;
        height: auto;
        padding: 10px 0;

        span.tag {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          width: 50%;
          height: auto;
          line-height: 20px;
          margin-right: 10px;
          padding: 10px 15px;
          font-size: 0.85rem;
          font-family: 'Lexend Deca';
          letter-spacing: 0.06rem;
          border: 1px solid #e5e5e5;
          box-shadow: -2px -2px 0px 0px #e5e5e5;

          strong {
            display: block;
            width: auto;
            height: auto;
            margin: 0 0 8px -6px;
            padding: 0 10px 3px 10px;
            font-size: 9px;
            letter-spacing: 0.05rem;
            font-weight: normal;
            border-radius: 25px;
          }
          strong.blue {
            background-color: #2f6ead;
          }

        }

        span.tag::-moz-selection {
          background-color: #e5e5e5;
        }

        span.tag::selection {
          background-color: #e5e5e5;
        }

      }

      span.element-tag {
        display: block;
        position: absolute;
        width: auto;
        height: auto;
        line-height: 14px;
        top: -22px;
        left: -4px;
        font-family: 'Lexend Deca';
        font-size: 0.9rem;
        color: #e5e5e5;
        background-color: #e5e5e5;
      }

      span.element-tag::selection {
        background-color: black;
      }

      span.element-tag::-moz-selection {
        background-color: black;
      }

      h3 {
        display: block;
        align-self: center;
        width: auto;
        height: auto;
        line-height: 40px;
        font-family: 'Turret Road';
        font-size: 1.5rem;
        text-align: center;
        font-weight: bolder;
        letter-spacing: 0.45rem;
      }

    }

    div.desc {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      flex-direction: column;
      width: 100%;
      height: auto;
      padding: 10px;
      background-color: rgba(0, 0, 0, 0.5);
      box-shadow: 4px 4px 0px 0px #e5e5e5;

      button.start-btn, button.stop-btn{
        display: block;
        cursor: pointer;
        width: 50%;
        height: auto;
        line-height: 40px;

        text-align: center;
        transition: 0s;
        -webkit-transition: 0s;
        -moz-transition: 0s;
        -o-transition: 0s;

        &:hover {

          transition: 0s;
          -webkit-transition: 0s;
          -moz-transition: 0s;
          -o-transition: 0s;
        }

      }
      button.start-btn{
        margin: 4px 0 2px 0;
        border: 1px solid #32a852;
        &:hover {
          margin: 6px 0 0 2px;
          box-shadow: -2px -2px 0 0 #32a852;
        }
      }
      button.stop-btn{
        margin: 12px 0 2px 0;
        border: 1px solid #bf5261;
        &:hover {
          margin: 14px 0 0 2px;
          box-shadow: -2px -2px 0 0 #bf5261;
        }
      }

      > div#game-section {
        display: flex;
        flex-grow: 1;
        width: 100%;
        height: auto;
      }

      span.element-tag {
        display: block;
        position: absolute;
        width: auto;
        height: auto;
        line-height: 14px;
        bottom: -22px;
        right: -4px;
        font-family: 'Lexend Deca';
        font-size: 0.9rem;
        color: #e5e5e5;
        background-color: #e5e5e5;
      }

      span.element-tag::selection {
        background-color: black;
      }

      span.element-tag::-moz-selection {
        background-color: black;
      }

    }

  }
}
@media screen and (max-width: 799px) {
  section.game {
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    width: auto;
    min-height: 100vh;
    height: auto;
    padding: 15% 20px;
    background-color: rgba(229, 229, 229, 0.3);

    aside.title {
      display: flex;
      flex-flow: column wrap;
      justify-content: flex-start;
      align-items: center;
      position: relative;
      width: 100%;
      height: auto;
      padding: 10px;
      padding-bottom: 0;
      background-color: rgba(0, 0, 0, 0.5);
      box-shadow: -4px -4px 0px 0px #e5e5e5;

      div.tags {
        display: flex;
        flex-flow: column wrap;
        width: auto;
        height: auto;
        padding: 10px 0;

        span.tag {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          width: auto;
          height: auto;
          line-height: 20px;
          margin-bottom: 10px;
          padding: 10px 15px;
          font-size: 0.85rem;
          font-family: 'Lexend Deca';
          letter-spacing: 0.06rem;
          border: 1px solid #e5e5e5;
          box-shadow: -2px -2px 0px 0px #e5e5e5;

          strong {
            display: block;
            width: auto;
            height: auto;
            margin: 0 0 8px -6px;
            padding: 0 10px 3px 10px;
            font-size: 9px;
            letter-spacing: 0.05rem;
            font-weight: normal;
            border-radius: 25px;
          }
          strong.blue {
            background-color: #2f6ead;
          }

        }

        span.tag:last-child {
          margin-bottom: 0;
        }

        span.tag::-moz-selection {
          background-color: #e5e5e5;
        }

        span.tag::selection {
          background-color: #e5e5e5;
        }

      }

      span.element-tag {
        display: block;
        position: absolute;
        width: auto;
        height: auto;
        line-height: 14px;
        top: -22px;
        left: -4px;
        font-family: 'Lexend Deca';
        font-size: 0.9rem;
        color: #e5e5e5;
        background-color: #e5e5e5;
      }

      span.element-tag::selection {
        background-color: black;
      }

      span.element-tag::-moz-selection {
        background-color: black;
      }

      h3 {
        display: block;
        align-self: center;
        width: auto;
        height: auto;
        line-height: 40px;
        font-family: 'Turret Road';
        font-size: 1.5rem;
        text-align: center;
        font-weight: bolder;
        letter-spacing: 0.45rem;
      }

    }

    div.desc {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      flex-direction: column;
      width: 100%;
      height: auto;
      padding: 10px;
      background-color: rgba(0, 0, 0, 0.5);
      box-shadow: 4px 4px 0px 0px #e5e5e5;

      button.start-btn, button.stop-btn{
        display: flex;
        justify-content: center;
        cursor: pointer;
        width: 100%;
        height: auto;
        line-height: 40px;
        margin: 10px 0 2px 0;
        text-align: center;
        transition: 0s;
        -webkit-transition: 0s;
        -moz-transition: 0s;
        -o-transition: 0s;

        &:hover {
          margin: 12px 0 0 2px;
          transition: 0s;
          -webkit-transition: 0s;
          -moz-transition: 0s;
          -o-transition: 0s;
        }

      }
      button.start-btn{
        margin: 2px 0 2px 0;
        border: 1px solid #32a852;
        &:hover {
          margin: 4px 0 0 2px;
          box-shadow: -2px -2px 0 0 #32a852;
        }
      }
      button.stop-btn{
        margin: 12px 0 2px 0;
        border: 1px solid #bf5261;
        &:hover {
          margin: 14px 0 0 2px;
          box-shadow: -2px -2px 0 0 #bf5261;
        }
      }

      > div#game-section {
        display: flex;
        flex-grow: 1;
        width: 100%;
        height: auto;
      }

      span.element-tag {
        display: block;
        position: absolute;
        width: auto;
        height: auto;
        line-height: 14px;
        bottom: -22px;
        right: -4px;
        font-family: 'Lexend Deca';
        font-size: 0.9rem;
        color: #e5e5e5;
        background-color: #e5e5e5;
      }

      span.element-tag::selection {
        background-color: black;
      }

      span.element-tag::-moz-selection {
        background-color: black;
      }

    }

  }
}
</style>