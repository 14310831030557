<template>
  <section class="projects">

    <aside class="title">
      <span class="element-tag">&lt;My Projects Section&gt;</span>
      <h3 class="pink-selection">Projects</h3>
      <div class="list">
        <span class="item" :class="{'active': projectFlag === 0}" @click="changeFlag(0)">Rust Game Server</span>
        <span class="item" :class="{'active': projectFlag === 1}" @click="changeFlag(1)">Coinkade (Exchange)</span>
        <span class="item" :class="{'active': projectFlag === 2}" @click="changeFlag(2)">TetherEx (Exchange)</span>
        <span class="item" :class="{'active': projectFlag === 3}" @click="changeFlag(3)">Remah Style Shop</span>
        <span class="item disabled">Travian Web Browser Server</span>
        <span class="item disabled">Online Card Game</span>
        <span class="item disabled">Football Manager Game</span>
      </div>
    </aside>

    <div class="desc">

      <project-template
          :project-data="allProjects[projectFlag]"
      />

      <span class="element-tag">&lt;&#8725;My Projects Section&gt;</span>
    </div>
  </section>
</template>

<script>
import {ref} from "vue";
import ProjectTemplate from "./ProjectTemplate.vue";
import projects from  "../projects.js";

export default {
  name: "IndexSkills",
  components: {
    ProjectTemplate
  },
  setup(){

    const allProjects = projects();

    const projectFlag = ref(0);

    function changeFlag(id){
      projectFlag.value = id;
    }

    return {allProjects, projectFlag, changeFlag}
  }
}
</script>

<style lang="scss" scoped>
@media screen and (min-width: 1200px){
  section.projects {
    display: flex;
    position: relative;
    justify-content: center;
    flex-grow: 1;
    width: auto;
    min-height: 100vh;
    height: auto;
    padding: 5% 10%;
    background-color: rgba(191, 82, 97, 0.3);

    aside.title {
      display: flex;
      position: relative;
      align-self: flex-start;
      flex-direction: column;
      width: 20%;
      height: auto;
      padding: 10px 0;
      background-color: rgba(0, 0, 0, 0.5);
      box-shadow: -4px -4px 0px 0px #bf5261;

      div.list {
        display: flex;
        flex-flow: column wrap;
        width: auto;
        height: auto;
        padding: 10px 0;

        span.item {
          display: block;
          cursor: pointer;
          width: auto;
          height: auto;
          line-height: 20px;
          padding: 10px 0;
          font-size: 1rem;
          font-family: 'Lexend Deca';
          text-align: center;
          letter-spacing: 0.06rem;


          &.disabled {
            color: gray;
            pointer-events: none;
          }

        }

        span.item:hover {
          background-color: #bf5261;
        }

        span.item.active {
          cursor: default;
          background-color: #bf5261;
        }

      }

      span.element-tag {
        display: block;
        position: absolute;
        width: auto;
        height: auto;
        line-height: 14px;
        top: -22px;
        left: -4px;
        font-family: 'Lexend Deca';
        font-size: 0.9rem;
        color: #bf5261;
        background-color: #bf5261;
      }

      span.element-tag::selection {
        background-color: black;
      }

      span.element-tag::-moz-selection {
        background-color: black;
      }

      h3 {
        display: block;
        width: 100%;
        height: auto;
        line-height: 40px;
        font-family: 'Turret Road';
        font-size: 1.5rem;
        text-align: center;
        font-weight: bolder;
        letter-spacing: 0.45rem;
      }

      img {
        width: 100%;
        height: auto;
        padding: 10px 0;
        border-radius: 50%;
      }

    }

    div.desc {
      display: flex;
      position: relative;
      flex-direction: column;
      width: calc(80% - 10px);
      height: auto;
      margin-left: 10px;
      padding: 20px;
      background-color: rgba(0, 0, 0, 0.5);
      box-shadow: 4px 4px 0 0 #bf5261;

      span.element-tag {
        display: block;
        position: absolute;
        width: auto;
        height: auto;
        line-height: 14px;
        bottom: -22px;
        right: -4px;
        font-family: 'Lexend Deca';
        font-size: 0.9rem;
        color: #bf5261;
        background-color: #bf5261;
      }

      span.element-tag::selection {
        background-color: black;
      }

      span.element-tag::-moz-selection {
        background-color: black;
      }

    }

  }
}
@media screen and (max-width: 1199px){
  section.projects {
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    position: relative;
    flex-grow: 1;
    width: auto;
    min-height: 100vh;
    height: auto;
    padding: 15% 20px;
    background-color: rgba(191, 82, 97, 0.3);

    aside.title {
      display: flex;
      position: relative;
      align-self: flex-start;
      flex-direction: column;
      width: 100%;
      height: auto;
      padding: 10px 0;
      background-color: rgba(0, 0, 0, 0.5);
      box-shadow: -4px -4px 0px 0px #bf5261;

      div.list {
        display: flex;
        flex-flow: column wrap;
        width: auto;
        height: auto;
        padding: 10px 0;

        span.item {
          display: block;
          cursor: pointer;
          width: auto;
          height: auto;
          line-height: 20px;
          padding: 10px 0;
          font-size: 1rem;
          font-family: 'Lexend Deca';
          text-align: center;
          letter-spacing: 0.06rem;

        }

        span.item:hover {
          background-color: #bf5261;
        }

        span.item.active {
          cursor: default;
          background-color: #bf5261;
        }

      }

      span.element-tag {
        display: block;
        position: absolute;
        width: auto;
        height: auto;
        line-height: 14px;
        top: -22px;
        left: -4px;
        font-family: 'Lexend Deca';
        font-size: 0.9rem;
        color: #bf5261;
        background-color: #bf5261;
      }

      span.element-tag::selection {
        background-color: black;
      }

      span.element-tag::-moz-selection {
        background-color: black;
      }

      h3 {
        display: block;
        width: 100%;
        height: auto;
        line-height: 40px;
        font-family: 'Turret Road';
        font-size: 1.5rem;
        text-align: center;
        font-weight: bolder;
        letter-spacing: 0.45rem;
      }

      img {
        width: 100%;
        height: auto;
        padding: 10px 0;
        border-radius: 50%;
      }

    }

    div.desc {
      display: flex;
      position: relative;
      flex-direction: column;
      width: 100%;
      height: auto;
      padding: 10px;
      background-color: rgba(0, 0, 0, 0.5);
      box-shadow: 4px 4px 0 0 #bf5261;

      span.element-tag {
        display: block;
        position: absolute;
        width: auto;
        height: auto;
        line-height: 14px;
        bottom: -22px;
        right: -4px;
        font-family: 'Lexend Deca';
        font-size: 0.9rem;
        color: #bf5261;
        background-color: #bf5261;
      }

      span.element-tag::selection {
        background-color: black;
      }

      span.element-tag::-moz-selection {
        background-color: black;
      }

    }

  }
}
</style>