import Phaser from "phaser";

let config = {
    type: Phaser.AUTO,
    width: 400,
    height: 300,
    physics: {
        default: 'arcade',
        arcade: {
            gravity: {y: 800},
            // debug: true
        }
    },
    scale: {
        mode: Phaser.Scale.FIT,
        autoCenter: Phaser.Scale.CENTER_BOTH
    },
    pixelArt: true,
    scene: {
        preload: preload,
        create: create,
        update: update
    }
};

// global variables
let key = false;
let objectsLayer;

// global variables

function preload() {

    this.load.image('tiles', 'map/tiles_packed.png');
    this.load.image('bgtiles', 'map/tilemap_packed.png');
    this.load.image('key', 'map/key.png');
    this.load.image('chest', 'map/chest.png');
    this.load.image('lever', 'map/lever_off.png');
    this.load.image('lever_on', 'map/lever_on.png');
    this.load.tilemapTiledJSON('map', "map/runner_map.json");

    this.load.spritesheet("guy", "map/guy.png", {
        frameWidth: 32,
        frameHeight: 31
    });

    this.load.bitmapFont(
        "main",
        "fonts/bitmapfont.png",
        "fonts/bitmapfont.xml"
    );

    this.input.addPointer(1);

}

function create() {

    this.cursor = this.input.keyboard.createCursorKeys();
    this.cursor.down = this.input.keyboard.addKey("Down", true, false);
    this.touchController = {
        right: false,
        left: false,
        direction: "none"
    };

    // create zone for touch controller inputs
    let w = 0.5 * config.width;
    let h = config.height;
    this.leftZone = this.add.zone(0, 0, w, h).setOrigin(0, 0).setScrollFactor(0, 0).setDepth(50).setInteractive();
    this.rightZone = this.add.zone(config.width, 0, w, h).setOrigin(1, 0).setScrollFactor(0, 0).setDepth(50).setInteractive();

    this.leftZone.on('pointerdown', () => {
        this.touchController.left = true;
        if (this.touchController.direction === "none") {
            this.touchController.direction = "left";
        }
    }, this);
    this.leftZone.on('pointerup', () => {
        this.touchController.left = false;
        if (this.touchController.direction === "left") {
            this.touchController.direction = "none";
        }
    }, this);
    this.leftZone.on('pointerout', () => {
        this.touchController.left = false;
        if (this.touchController.direction === "left") {
            this.touchController.direction = "none";
        }
    }, this);

    this.rightZone.on('pointerdown', () => {
        this.touchController.right = true;
        if (this.touchController.direction === "none") {
            this.touchController.direction = "right";
        }
    }, this);
    this.rightZone.on('pointerup', () => {
        this.touchController.right = false;
        if (this.touchController.direction === "right") {
            this.touchController.direction = "none";
        }
    }, this);
    this.rightZone.on('pointerout', () => {
        this.touchController.right = false;
        if (this.touchController.direction === "right") {
            this.touchController.direction = "none";
        }
    }, this);


    let map = this.make.tilemap({key: 'map'});

    let tileset = map.addTilesetImage("tileset", "tiles");
    let bgtileset = map.addTilesetImage("bg_tileset", "bgtiles");

    // let world = map.createLayer('ground');
    map.createLayer('bg', bgtileset);
    const worldLayer = map.createLayer('world', tileset);
    map.createLayer('frontview', tileset).setDepth(10);
    objectsLayer = map.getObjectLayer('objectsLayer')["objects"];

    worldLayer.setCollisionByProperty({collides: true});


    // ---------------- player section ----------------
    this.player = this.physics.add.sprite(15, 400, "guy").setScale(0.5);
    this.player.jumps = 0;
    this.anims.create({
        key: "right",
        frames: this.anims.generateFrameNumbers("guy", {
            start: 0,
            end: 3
        }),
        frameRate: 12,
        repeat: -1
    });
    this.anims.create({
        key: "left",
        frames: this.anims.generateFrameNumbers("guy", {
            start: 4,
            end: 7
        }),
        frameRate: 12,
        repeat: -1
    });
    // -------------- end of player section --------------


    // ------------- in-game objects section -------------
    this.key = this.physics.add.staticSprite(objectsLayer[0].x + 9, objectsLayer[0].y + 9, "key").setVisible(false);
    this.chest = this.physics.add.staticSprite(objectsLayer[1].x + 10, objectsLayer[1].y + 9, "chest").setDepth(60).setInteractive();
    this.chest.on("pointerdown", () => {
        if (this.physics.world.overlap(this.player, this.chest)) {
            if (key) {
                if(chestOpened === ""){
                    chestOpened = this.add.bitmapText(objectsLayer[1].x - 130, objectsLayer[1].y - 20, "main", "The chest is empty ! sry", 16).setDepth(100).setOrigin(0);
                    setTimeout(() => {
                        chestOpened.destroy();
                        chestOpened = "";
                    }, 2500);
                }
            } else {
                if(keyErr === ""){
                    keyErr = this.add.bitmapText(objectsLayer[1].x - 80, objectsLayer[1].y - 20, "main", "You need a key !", 16).setDepth(100).setOrigin(0);
                    setTimeout(() => {
                        keyErr.destroy();
                        keyErr = "";
                    }, 2500);
                }
            }
        }
    }, this);

    this.lever = this.physics.add.staticSprite(objectsLayer[2].x + 10, objectsLayer[2].y + 9, "lever").setDepth(60).setInteractive();
    this.lever.on("pointerdown", () => {
        if (this.physics.world.overlap(this.player, this.lever)) {
            if (this.key.visible) {
                this.lever.setTexture("lever");
                this.key.setVisible(false);
            } else {
                this.lever.setTexture("lever_on");
                this.key.setVisible(true);
            }
        }
    }, this);
    // --------- end of in-game objects section ----------


    // world physics and collides
    this.player.setCollideWorldBounds(true);
    this.physics.add.collider(this.player, worldLayer);
    this.physics.world.bounds.width = 720;
    this.physics.world.bounds.height = 540;

    this.cameras.main.setBounds(0, 0, 720, 540);
    this.cameras.main.startFollow(this.player, true, 0.8, 0.8);

}

let chestOpened = "";
let keyErr = "";

function update() {
    const speed = 60;

    if (this.cursor.right.isDown || this.touchController.direction === "right") {
        this.player.anims.play("right", true);
        this.player.body.setVelocityX(speed);
    } else if (this.cursor.left.isDown || this.touchController.direction === "left") {
        this.player.anims.play("left", true);
        this.player.body.setVelocityX(-speed);
    } else {
        this.player.anims.stop();
        if (this.player.body.onFloor()) {
            this.player.body.setVelocity(0);
        }
    }

    if (this.cursor.up.isDown || (this.touchController.left && this.touchController.right)) {
        if (this.player.jumps === 0 && this.player.body.onFloor()) {
            this.player.jumps = 1;
            this.player.body.setVelocityY(-speed * 4.7);
        } else if (this.player.body.onFloor()) {
            this.player.jumps = 0;
        }
    }

    if (this.input.keyboard.checkDown(this.cursor.down, 1000)) {
        if (this.physics.world.overlap(this.player, this.lever)) {
            if (this.key.visible) {
                this.lever.setTexture("lever");
                this.key.setVisible(false);
            } else {
                this.lever.setTexture("lever_on");
                this.key.setVisible(true);
            }
        } else if (this.physics.world.overlap(this.player, this.chest)) {
            if (key) {
                if(chestOpened === ""){
                    chestOpened = this.add.bitmapText(objectsLayer[1].x - 130, objectsLayer[1].y - 20, "main", "The chest is empty ! sry", 16).setDepth(100).setOrigin(0);
                    setTimeout(() => {
                        chestOpened.destroy();
                        chestOpened = "";
                    }, 2500);
                }
            } else {
                if(keyErr === ""){
                    keyErr = this.add.bitmapText(objectsLayer[1].x - 80, objectsLayer[1].y - 20, "main", "You need a key !", 16).setDepth(100).setOrigin(0);
                    setTimeout(() => {
                        keyErr.destroy();
                        keyErr = "";
                    }, 2500);
                }
            }
        }
    }

    if (this.physics.world.overlap(this.player, this.key) && this.key.visible) {
        this.key.destroy();
        key = true;
    }

}

let game;

function launch(containerId) {
    console.log(containerId);
    config.parent = containerId;
    game = new Phaser.Game(config);
    return game;
}

function remove() {
    return game.destroy(true, false);
}

export {launch, remove}