<template>

  <div>
    <span class="tag" :class="{'no-year': !printYear}" :style="{backgroundColor: color, color: textColor}">
      <slot name="icon"></slot>
      <slot name="name">Unknown</slot>
    </span>
    <span v-if="printYear" class="start-in" :class="{'has-more-tag': stillLearning || notInterest}">
      Since
      <slot name="start-year">2013</slot>
    </span>
    <span class="still-learning" v-if="stillLearning">
      Still Learning
    </span>
    <span class="not-interest" v-else-if="notInterest">
      Not Interest
    </span>
  </div>

</template>

<script>

export default {
  name: "SkillsCapsule",
  props: {
    name: String,
    color: String,
    textColor: String,
    elName: String,
    stillLearning: Boolean,
    notInterest: Boolean,
    printYear: {
      default: true,
      require: false,
      type: Boolean
    },
  }

}
</script>

<style lang="scss" scoped>

div {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: auto;
  height: auto;
  margin: 0 8px 15px 0;
  border-radius: 25px;

  > span {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    width: auto;
    height: auto;
    line-height: 20px;
    padding-bottom: 2px;
    font-family: 'Lexend Deca', sans-serif;

    :slotted(img) {
      width: auto;
      height: 16px;
      margin: 0 2px -1px 0;
    }

  }

  > span.tag {
    padding: 0 8px 2px 8px;
    border-radius: 25px 0 0 25px;
  }
  > span.tag.no-year {
    border-radius: 25px;
  }
  > span.start-in {
    align-self: flex-start;
    font-size: 10px;
    letter-spacing: 0.05rem;
    font-weight: normal;
    padding: 0 6px 2px 4px;
    border-radius: 0 25px 25px 0;
    color: #32a852;
    background-color: #171717;
  }

  > span.start-in.has-more-tag {
    border-radius: 0;
  }

  > span.still-learning {
    border-radius: 0 25px 25px 0;
    align-self: flex-start;
    font-size: 9px;
    letter-spacing: 0.05rem;
    font-weight: normal;
    padding: 0 6px 2px 4px;
    background-color: #2f6ead;
  }

  > span.not-interest {
    border-radius: 0 25px 25px 0;
    align-self: flex-start;
    font-size: 9px;
    letter-spacing: 0.05rem;
    font-weight: normal;
    padding: 0 6px 2px 4px;
    background-color: #bf7639;
  }

}
</style>