export default function () {

  const projects = [
    {
      name: "Rust Game Server",
      img: require('@/assets/projects/1.jpg'),
      desc: "A non-official Rust Game Server with oxide mod which developed around 2015 and had an api which was developed to connect the game server to the website, so the players going to buy in-game resource items or some other in-game kits easier. It was a good project for me because I learned so many things in that.",
      techs: ["html5", "css3", "javascript", "jquery", "php7", "mysql", "ajax", "responsive", "linux", "servermanager", "vbnet", "lua"]
    },
    {
      name: "Coinkade (Exchange)",
      img: require('@/assets/projects/3.png'),
      desc: "An Iranian crypto currency exchange with +500 coin support and over 10K active user. VueJs and NuxtJs has been used as client-side framework and angular as admin-side.",
      techs: ["html5", "css3", "javascript", "typescript", "bootstrap", "sass", "docker", "websocket", "vuejs", "nuxtjs", "angular", "restapi", "responsive", "bootstrap", "linux", "servermanager", "socketio"]
    },
    {
      name: "TetherEx (Exchange)",
      img: require('@/assets/projects/4.png'),
      desc: "An Iranian tether exchange which was a good experience for me.",
      techs: ["html5", "css3", "javascript", "typescript", "vuejs", "nuxtjs", "docker", "websocket", "sass", "restapi", "responsive", "linux", "servermanager"]
    },
    {
      name: "Remah Style Shop",
      img: require('@/assets/projects/2.jpg'),
      desc: "An online wearing stuff shop such as shirts, T-shirts, Jeans, Blouse, Scarfs and ... , I worked as a full-stack developer in this project.",
      techs: ["html5", "css3", "javascript", "sass", "vuejs", "php8", "mariadb", "docker", "restapi", "responsive", "linux", "servermanager"]
    },
    /*
    {
      name: "Football Manager Game",
      img: require('@/assets/projects/2.jpg'),
      desc: "A football manager game, developed ",
      techs: ["html5", "css3", "javascript", "sass", "vuejs", "php8", "mariadb", "restapi", "responsive", "linux", "servermanager"]
    }*/
  ];

  return projects;

}