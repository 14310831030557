<template>

  <index-header/>

  <index-about id="about-section"/>

  <index-skills id="skills-section"/>

  <index-projects id="projects-section"/>

  <index-game id="game-section"/>

  <index-contact id="contact-section"/>

</template>

<script>
import IndexHeader from "./components/IndexHeader.vue";
import IndexAbout from "./components/IndexAbout.vue";
import IndexSkills from "./components/IndexSkills.vue";
import IndexProjects from "./components/IndexProjects.vue";
import IndexGame from "./components/IndexGame.vue";
import IndexContact from "./components/IndexContact.vue";

export default {
  name: 'App',
  components: {
    IndexHeader,
    IndexAbout,
    IndexSkills,
    IndexProjects,
    IndexGame,
    IndexContact
  }
}
</script>

<style scoped>

</style>
