import {createRouter, createWebHistory} from 'vue-router';
import App from './App.vue';
import IndexAbout from "./components/IndexAbout";
import IndexSkills from "./components/IndexSkills";
import IndexProjects from "./components/IndexProjects";
import IndexGame from "./components/IndexGame";
import IndexContact from "./components/IndexContact";

const routes = [
    {path: "/", name: "Index", component: App,},
    {path: "/about", name: "about-section", component: IndexAbout,},
    {path: "/skills", name: "skills-section", component: IndexSkills,},
    {path: "/projects", name: "projects-section", component: IndexProjects,},
    {path: "/game", name: "game-section", component: IndexGame,},
    {path: "/contact", name: "contact-section", component: IndexContact,},
];

const router = createRouter({
    history: createWebHistory(),
    routes: routes,
    scrollBehavior(to) {
        let element = "#"+to.name;
        return {
            el: element,
            behavior: 'smooth',
        }
    }
});


export default router;