<template>
  <section class="about-me">
    <aside class="title">
      <span class="element-tag">&lt;About Me Section&gt;</span>
      <h3 class="selection">About Me</h3>
      <img class="selection" :src="require('@/assets/en.jpg')" alt="">
      <div class="tags desktop">
        <span class="tag"> <i></i> Full-Stack Web Developer</span>
        <span class="tag"> <i></i> Open To Work</span>
        <span class="tag"> <i></i> Remote</span>
        <span class="tag"> <i></i> CDN Manager</span>
        <span class="tag"> <i></i> UX</span>
        <span class="tag"> <i></i> UI</span>
        <span class="tag"> <i></i> Server Manager</span>
        <span class="tag"> <i></i> NewFolder13</span>
      </div>
    </aside>
    <div class="desc">

      <div>
        <h3>Description</h3>
        <p>I learned web development with a beginner course includes "HTML, CSS, JavaScript, PHP, Recognize Databases" in 2013 when I was in high school. after completing my course which was a 6-month term I involved with lots of project, Joined different teams and forums to earn more experience and use it. After years still thinking about that it's not enough still and I need to know more.
        <br>
        At last I'm trying to say that I have so many dream for myself and I'm not gonna stop here and still need more knowledge to let my mind get grow in this field.
        </p>
      </div>

      <div>
        <h3>Interested in</h3>
        <p>Astronomy (our Galaxy and such it), AI Discoveries, Video Games, Psychological Discussions, WebDesigns, Simplification in everything</p>
      </div>

      <div class="tags mobile">
        <span class="tag"> <i></i> Full-Stack Web Developer</span>
        <span class="tag"> <i></i> Open To Work</span>
        <span class="tag"> <i></i> Remote</span>
        <span class="tag"> <i></i> CDN Manager</span>
        <span class="tag"> <i></i> UX</span>
        <span class="tag"> <i></i> UI</span>
        <span class="tag"> <i></i> Server Manager</span>
        <span class="tag"> <i></i> NewFolder13</span>
      </div>

      <span class="element-tag">&lt;&#8725;About Me Section&gt;</span>
    </div>
  </section>
</template>

<script>
export default {
  name: "IndexAbout"
}
</script>

<style lang="scss" scoped>
@media screen and (min-width: 1200px){
  section.about-me{
    display: flex;
    justify-content: center;
    flex-grow: 1;
    width: auto;
    min-height: 100vh;
    height: auto;
    padding: 5% 10%;
    background-color: rgba(79, 84, 168, 0.3);

    aside.title {
      display: flex;
      position: relative;
      align-self: flex-start;
      flex-direction: column;
      width: 20%;
      height: auto;
      padding: 10px;
      background-color: rgba(0, 0, 0, 0.5);
      box-shadow: -4px -4px 0px 0px #4f54a8;

      div.tags{
        display: flex;
        flex-flow: row wrap;
        width: auto;
        height: auto;
        padding: 10px 0;

        span.tag {
          display: flex;
          flex-direction: row;
          align-items: center;
          width: auto;
          height: auto;
          line-height: 20px;
          margin: 6px 4px 0 0;
          padding: 0 7px 0 5px;
          font-size: 0.8rem;
          font-family: 'Lexend Deca';
          letter-spacing: 0.06rem;
          border-radius: 25px;
          background-color: #4f54a8;

          i {
            display: block;
            width: 12px;
            height: 12px;
            margin-right: 5px;
            border-radius: 50%;
            background-color: #E0E0E0;
          }

        }

      }

      span.element-tag{
        display: block;
        position: absolute;
        width: auto;
        height: auto;
        line-height: 14px;
        top: -22px;
        left: -4px;
        font-family: 'Lexend Deca';
        font-size: 0.9rem;
        color: #4f54a8;
        background-color: #4f54a8;
      }
      span.element-tag::selection{
        background-color: black;
      }
      span.element-tag::-moz-selection{
        background-color: black;
      }

      h3{
        display: block;
        width: 100%;
        height: auto;
        line-height: 40px;
        font-family: 'Turret Road';
        font-size: 1.5rem;
        text-align: center;
        font-weight: bolder;
        letter-spacing: 0.45rem;
      }

      img {
        width: 100%;
        height: auto;
        padding: 10px 0;
        border-radius: 50%;
      }

    }

    div.desc {
      display: flex;
      position: relative;
      flex-direction: column;
      width: calc(80% - 10px);
      height: auto;
      margin-left: 10px;
      padding: 20px;
      background-color: rgba(0, 0, 0, 0.5);
      box-shadow: 4px 4px 0px 0px #4f54a8;

      > div {
        display: flex;
        flex-flow: column wrap;
        width: auto;
        height: auto;

        h3 {
          display: block;
          width: auto;
          height: auto;
          line-height: 26px;
          padding: 2px 12px 0 12px;
          font-family: 'Turret Road';
          font-size: 1.1rem;
          color: #171717;
          background-color: #4f54a8;
        }

        > p {
          display: block;
          width: 100%;
          height: auto;
          line-height: 20px;
          margin-bottom: 40px;
          padding: 20px 15px 15px 15px;
          font-family: 'Lexend Deca', sans-serif;
          font-size: 0.95rem;
          letter-spacing: 0.05rem;
          background-color: rgba(79, 84, 168, 0.2);
        }

      }

      div.tags.mobile { display: none; }

      span.element-tag{
        display: block;
        position: absolute;
        width: auto;
        height: auto;
        line-height: 14px;
        bottom: -22px;
        right: -4px;
        font-family: 'Lexend Deca';
        font-size: 0.9rem;
        color: #4f54a8;
        background-color: #4f54a8;
      }
      span.element-tag::selection{
        background-color: black;
      }
      span.element-tag::-moz-selection{
        background-color: black;
      }

    }

  }
}
@media screen and (max-width: 1199px){
  section.about-me{
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    width: auto;
    min-height: 100vh;
    height: auto;
    padding: 15% 20px;
    background-color: rgba(79, 84, 168, 0.3);
    //background-color: rgba(209, 209, 82, 0.2);

    aside.title {
      display: flex;
      flex-flow: column wrap;
      justify-content: flex-start;
      align-items: center;
      position: relative;
      width: auto;
      height: auto;
      padding: 10px;
      background-color: rgba(0, 0, 0, 0.5);
      box-shadow: -4px -4px 0px 0px #4f54a8;

      span.element-tag{
        display: block;
        position: absolute;
        width: auto;
        height: auto;
        line-height: 14px;
        top: -22px;
        left: -4px;
        font-family: 'Lexend Deca';
        font-size: 0.9rem;
        color: #4f54a8;
        background-color: #4f54a8;
      }
      span.element-tag::selection{
        background-color: black;
      }
      span.element-tag::-moz-selection{
        background-color: black;
      }

      h3{
        display: block;
        align-self: center;
        width: auto;
        height: auto;
        line-height: 40px;
        font-family: 'Turret Road';
        font-size: 1.5rem;
        text-align: center;
        font-weight: bolder;
        letter-spacing: 0.45rem;
      }

      img {
        width: 200px;
        height: auto;
        border-radius: 50%;
      }

      div.tags.desktop{ display: none; }

    }

    div.desc {
      display: flex;
      position: relative;
      flex-direction: column;
      width: 100%;
      height: auto;
      padding: 10px;
      background-color: rgba(0, 0, 0, 0.5);
      box-shadow: 4px 4px 0px 0px #4f54a8;

      > div {
        display: flex;
        flex-flow: column wrap;
        width: auto;
        height: auto;

        h3 {
          display: block;
          width: auto;
          height: auto;
          line-height: 26px;
          padding: 2px 12px 0 12px;
          font-family: 'Turret Road';
          font-size: 1.1rem;
          color: #171717;
          background-color: #4f54a8;
        }

        > p {
          display: block;
          width: 100%;
          height: auto;
          line-height: 20px;
          margin-bottom: 20px;
          padding: 20px 15px 15px 15px;
          font-family: 'Lexend Deca', sans-serif;
          font-size: 0.95rem;
          letter-spacing: 0.05rem;
          background-color: rgba(79, 84, 168, 0.2);

        }

      }

      div.tags.mobile{
        display: flex;
        order: 1;
        flex-flow: row wrap;
        width: auto;
        height: auto;
        margin-top: -15px;

        span.tag {
          display: flex;
          flex-direction: row;
          align-items: center;
          width: auto;
          height: auto;
          line-height: 20px;
          margin: 6px 4px 0 0;
          padding: 0 7px 0 5px;
          font-size: 0.8rem;
          font-family: 'Lexend Deca';
          letter-spacing: 0.06rem;
          border-radius: 25px;
          background-color: #4f54a8;

          i {
            display: block;
            width: 12px;
            height: 12px;
            margin-right: 5px;
            border-radius: 50%;
            background-color: #E0E0E0;
          }

        }

      }

      span.element-tag{
        display: block;
        position: absolute;
        width: auto;
        height: auto;
        line-height: 14px;
        bottom: -22px;
        right: -4px;
        font-family: 'Lexend Deca';
        font-size: 0.9rem;
        color: #4f54a8;
        background-color: #4f54a8;
      }
      span.element-tag::selection{
        background-color: black;
      }
      span.element-tag::-moz-selection{
        background-color: black;
      }

    }

  }
}
</style>