<template>

  <section class="header">
    <h1 class="selection desktop">&lt; Ehsan Nazemi &gt;</h1>
    <h1 class="selection mobile">Ehsan Nazemi</h1>
    <nav>
      <router-link to="about" id="about-link">About</router-link>
      <router-link to="skills" id="skills-link">Skills</router-link>
      <router-link to="projects" id="projects-link">Projects</router-link>
      <router-link to="game" id="game-link">Play Wheelchair Guy!</router-link>
      <router-link to="contact" id="contact-link">Contact</router-link>
    </nav>
  </section>

</template>

<script>
export default {
  name: "IndexHeader"
}
</script>

<style lang="scss" scoped>

@media screen and (min-width: 800px){
  %fancy-link {
    position: relative;
    text-decoration: none;
    transition: all 0.15s ease-out;
    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 4px;
      bottom: -4px;
      left: 0;
      border-radius: 5px;
      background: #4f54a8;
      visibility: hidden;
      transform: scaleX(0);
      transition: all 0.2s ease-in-out 0s;
    }
    &:hover {
      transition: all 0.1s ease-out;
      &:before {
        visibility: visible;
        transform: scaleX(1);
      }
    }
  }

  section.header{

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;

    h1{
      display: block;
      width: 100%;
      height: auto;
      font-family: 'Turret Road';
      font-size: 4rem;
      text-align: center;
      letter-spacing: 0.45rem;
      text-shadow: 0 0 4px #fff;
    }
    h1.mobile{ display: none; }

    nav {
      display: flex;
      flex-flow: row wrap;
      width: auto;
      height: auto;
      margin: 30px 0 0 0;

      a {
        display: block;
        width: auto;
        height: auto;
        line-height: 30px;
        margin: 0 15px;
        padding: 0 2px;
        font-size: 1.05rem;
        letter-spacing: 0.1rem;
        @extend %fancy-link;
      }

      a#skills-link:before{
        background: #32a852;
      }
      a#projects-link:before{
        background: #bf5261;
      }
      a#game-link:before{
        background: #e5e5e5;
      }
      a#contact-link:before{
        background: #fcc603;
      }

    }

  }
}

@media screen and (max-width: 799px){

  section.header{

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;

    h1{
      display: block;
      width: 100%;
      height: auto;
      line-height: 6rem;
      padding: 0 3%;
      font-family: 'Turret Road';
      font-size: 4rem;
      text-align: center;
      letter-spacing: 0.45rem;
      text-shadow: 0 0 4px #fff;
    }
    h1.desktop{ display: none; }

    nav {
      display: flex;
      flex-flow: column wrap;
      width: 70%;
      height: auto;
      margin: 30px 0 0 0;

      a {
        display: block;
        width: 100%;
        height: auto;
        line-height: 4rem;
        margin: 2px 0;
        padding: 0 0 4px 0;
        text-align: center;
        font-size: 1.2rem;
        letter-spacing: 0.1rem;
        border-radius: 1px;
      }

      a#about-link:hover, a#about-link:active{
        background: rgba(79, 84, 168, 0.3);
      }

      a#skills-link:hover, a#skills-link:active{
        background: rgba(50, 168, 82, 0.3);
      }

      a#projects-link:hover, a#projects-link:active{
        background: rgba(191, 82, 97, 0.3);
      }

      a#game-link:before{
        background: rgba(229, 229, 229, 0.3);
      }

      a#contact-link:before{
        background: rgba(252, 198, 3, 0.3);
      }


    }

  }
}

</style>
