<template>
  <section class="game">
    <aside class="title">
      <span class="element-tag">&lt;Contact Me Section&gt;</span>
      <h3 class="yellow-selection">Contact Me</h3>
      <div class="tags">
        <span class="tag">
          <strong class="blue">Immediately</strong>
          I'll get notice immediately when you send me your message, If you want
          to get a respond from me, don't forget to put your contact information
          there or I won't be able to find you.
        </span>
        <span class="tag">
          <strong class="blue">Stop Game</strong>
          Stop game before you start writing, If the game is running, you won't
          be able to write correctly and you may get different issues.
        </span>
      </div>
    </aside>

    <div class="desc">
      <form @submit.prevent="applyForm">
        <span>
          <label for="full-name">Full Name *</label>
          <input
              id="full-name"
              name="fullname"
              type="text"
              placeholder="Full Name"
              v-model="formData.fullName"
          />
        </span>
        <span>
          <label for="email">Email</label>
          <input
              id="email"
              name="email"
              type="email"
              placeholder="example@gmail.com"
              v-model="formData.email"
          />
        </span>
        <span>
          <label for="phonenumber">Phone Number</label>
          <input
              id="phonenumber"
              name="phonenumber"
              type="tel"
              placeholder="ex: 0912 3456 789"
              v-model="formData.phoneNumber"
          />
        </span>
        <span>
          <label for="message">Message *</label>
          <textarea
              id="message"
              name="message"
              minlength="100"
              placeholder="Write your message here"
              v-model="formData.message"
          ></textarea>
        </span>
        <VueRecaptcha
            style="margin: 0 auto 1.5rem auto"
            v-model="formData.captcha"
            :sitekey="'6Lc2u7QUAAAAANL_OhT-TgfWYyQhmg16t-ztl2sj'"
            :load-recaptcha-script="true"
            @verify="handleSuccess"
            @error="handleError"
        ></VueRecaptcha>
        <button>Submit Message</button>
      </form>

      <span class="element-tag">&lt;&#8725;Contact Me Section&gt;</span>
    </div>
  </section>
</template>

<script setup>
import {inject, reactive} from "vue";
import {VueRecaptcha} from "vue-recaptcha";

const axios = inject("axios");

const formData = reactive({
  fullName: "",
  email: "",
  phoneNumber: "",
  message: "",
});

const applyForm = async () => {
  // formData["g-recaptcha-response"] = grecaptcha.getResponse();
  try {
    let resp = await axios.post("https://ehsannazemi.ir/contactme.php", formData);
    formData.fullName = '';
    formData.email = '';
    formData.phoneNumber = '';
    formData.message = '';
    alert(resp.msg);
  } catch (err) {
    console.log(err);
  }
};
</script>

<style lang="scss" scoped>
@media screen and (min-width: 1200px) {
  section.game {
    display: flex;
    justify-content: center;
    flex-grow: 1;
    width: auto;
    min-height: 100vh;
    height: auto;
    padding: 5% 10%;
    background-color: rgba(252, 198, 3, 0.3);

    aside.title {
      display: flex;
      position: relative;
      align-self: flex-start;
      flex-direction: column;
      width: 20%;
      height: auto;
      padding: 10px;
      background-color: rgba(0, 0, 0, 0.5);
      box-shadow: -4px -4px 0px 0px #fcc603;

      div.tags {
        display: flex;
        flex-flow: row wrap;
        width: auto;
        height: auto;
        padding: 10px 0;

        span.tag {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          width: auto;
          height: auto;
          line-height: 20px;
          margin-bottom: 10px;
          padding: 10px 15px;
          font-size: 0.85rem;
          font-family: "Lexend Deca";
          letter-spacing: 0.06rem;
          border: 1px solid #fcc603;
          box-shadow: -2px -2px 0px 0px #fcc603;

          strong {
            display: block;
            width: auto;
            height: auto;
            margin: 0 0 8px -6px;
            padding: 0 10px 3px 10px;
            font-size: 9px;
            letter-spacing: 0.05rem;
            font-weight: normal;
            border-radius: 25px;

            &::selection {
              color: #171717;
              background-color: #fcc603;
            }
          }

          strong.blue {
            background-color: #2f6ead;
          }

          strong.orange {
            background-color: #bf7639;
          }
        }

        span.tag::-moz-selection {
          color: #171717;
          background-color: #fcc603;
        }

        span.tag::selection {
          color: #171717;
          background-color: #fcc603;
        }
      }

      span.element-tag {
        display: block;
        position: absolute;
        width: auto;
        height: auto;
        line-height: 14px;
        top: -22px;
        left: -4px;
        font-family: "Lexend Deca";
        font-size: 0.9rem;
        color: #fcc603;
        background-color: #fcc603;
      }

      span.element-tag::selection {
        background-color: black;
      }

      span.element-tag::-moz-selection {
        background-color: black;
      }

      h3 {
        display: block;
        width: 100%;
        height: auto;
        line-height: 40px;
        font-family: "Turret Road";
        font-size: 1.5rem;
        text-align: center;
        font-weight: bolder;
        letter-spacing: 0.45rem;
      }

      img {
        width: 100%;
        height: auto;
        padding: 10px 0;
        border-radius: 50%;
      }
    }

    div.desc {
      display: flex;
      position: relative;
      flex-direction: column;
      width: calc(80% - 10px);
      height: auto;
      margin-left: 10px;
      padding: 20px;
      background-color: rgba(0, 0, 0, 0.5);
      box-shadow: 4px 4px 0px 0px #fcc603;

      form {
        display: flex;
        flex-flow: column wrap;
        flex-grow: 1;
        width: 100%;
        height: auto;

        span {
          display: flex;
          flex-flow: row nowrap;
          align-items: flex-start;
          width: 100%;
          height: auto;
          margin-bottom: 20px;
          padding: 10px;
          border: 1px solid #fcc603;
          box-shadow: -2px -2px 0px 0px #fcc603;

          label {
            display: block;
            width: 140px;
            height: auto;
            padding: 0 10px;
            font-size: 1.1rem;

            &::-moz-selection {
              color: #171717;
              background-color: #fcc603;
            }
          }

          input {
            display: block;
            align-self: center;
            width: 200px;
            height: auto;
            line-height: 24px;
            padding: 0 8px;
            word-spacing: normal;
            color: #171717;
            border-radius: 1px;
            background-color: #d2d2d2;

            &::-moz-selection {
              color: #171717;
              background-color: #fcc603;
            }
          }

          textarea {
            flex-grow: 1;
            min-height: 200px;
            max-height: 200px;
            height: 150px;
            line-height: 18px;
            padding: 8px;
            word-spacing: normal;
            color: #171717;
            border-radius: 1px;
            background-color: #d2d2d2;

            &::-moz-selection {
              color: #171717;
              background-color: #fcc603;
            }
          }
        }

        button {
          display: block;
          align-self: center;
          cursor: pointer;
          width: 50%;
          height: auto;
          line-height: 40px;
          text-align: center;
          font-size: 1.2rem;
          font-weight: bold;
          border-radius: 1px;
          border: 1px solid #fcc603;
          box-shadow: 0 0 0 0 #fcc603;
          transition: 0s;
          -webkit-transition: 0s;
          -moz-transition: 0s;
          -o-transition: 0s;

          &:hover {
            margin: 2px 0 0 2px;
            box-shadow: -2px -2px 0 0 #fcc603;
            transition: 0s;
            -webkit-transition: 0s;
            -moz-transition: 0s;
            -o-transition: 0s;
          }
        }
      }

      span.element-tag {
        display: block;
        position: absolute;
        width: auto;
        height: auto;
        line-height: 14px;
        bottom: -22px;
        right: -4px;
        font-family: "Lexend Deca";
        font-size: 0.9rem;
        color: #fcc603;
        background-color: #fcc603;
      }

      span.element-tag::selection {
        background-color: black;
      }

      span.element-tag::-moz-selection {
        background-color: black;
      }
    }
  }
}

@media screen and (min-width: 800px) and (max-width: 1199px) {
  section.game {
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    flex-grow: 1;
    width: auto;
    min-height: 100vh;
    height: auto;
    padding: 10% 20px;
    background-color: rgba(252, 198, 3, 0.3);

    aside.title {
      display: flex;
      flex-flow: column wrap;
      justify-content: flex-start;
      align-items: center;
      position: relative;
      flex-grow: 1;
      width: 100%;
      height: auto;
      padding: 10px;
      padding-bottom: 0;
      background-color: rgba(0, 0, 0, 0.5);
      box-shadow: -4px -4px 0px 0px #fcc603;

      div.tags {
        display: flex;
        max-width: 100%;
        width: auto;
        height: auto;
        padding: 10px 0;

        span.tag {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          width: 50%;
          height: auto;
          line-height: 20px;
          margin-right: 10px;
          padding: 10px 15px;
          font-size: 0.85rem;
          font-family: "Lexend Deca";
          letter-spacing: 0.06rem;
          border: 1px solid #fcc603;
          box-shadow: -2px -2px 0px 0px #fcc603;

          strong {
            display: block;
            width: auto;
            height: auto;
            margin: 0 0 8px -6px;
            padding: 0 10px 3px 10px;
            font-size: 9px;
            letter-spacing: 0.05rem;
            font-weight: normal;
            border-radius: 25px;
          }

          strong.blue {
            background-color: #2f6ead;
          }
        }

        span.tag::-moz-selection {
          background-color: #fcc603;
        }

        span.tag::selection {
          background-color: #fcc603;
        }
      }

      span.element-tag {
        display: block;
        position: absolute;
        width: auto;
        height: auto;
        line-height: 14px;
        top: -22px;
        left: -4px;
        font-family: "Lexend Deca";
        font-size: 0.9rem;
        color: #fcc603;
        background-color: #fcc603;
      }

      span.element-tag::selection {
        background-color: black;
      }

      span.element-tag::-moz-selection {
        background-color: black;
      }

      h3 {
        display: block;
        align-self: center;
        width: auto;
        height: auto;
        line-height: 40px;
        font-family: "Turret Road";
        font-size: 1.5rem;
        text-align: center;
        font-weight: bolder;
        letter-spacing: 0.45rem;
      }
    }

    div.desc {
      display: flex;
      position: relative;
      flex-direction: column;
      width: 100%;
      height: auto;
      padding: 10px;
      background-color: rgba(0, 0, 0, 0.5);
      box-shadow: 4px 4px 0px 0px #fcc603;

      form {
        display: flex;
        flex-flow: column wrap;
        flex-grow: 1;
        width: 100%;
        height: auto;

        span {
          display: flex;
          flex-flow: row nowrap;
          align-items: flex-start;
          width: 100%;
          height: auto;
          margin-bottom: 20px;
          padding: 10px;
          border: 1px solid #fcc603;
          box-shadow: -2px -2px 0px 0px #fcc603;

          label {
            display: block;
            width: 140px;
            height: auto;
            padding: 0 10px;
            font-size: 1.1rem;

            &::-moz-selection {
              color: #171717;
              background-color: #fcc603;
            }
          }

          input {
            display: block;
            align-self: center;
            width: 200px;
            height: auto;
            line-height: 24px;
            padding: 0 8px;
            word-spacing: normal;
            color: #171717;
            border-radius: 1px;
            background-color: #d2d2d2;

            &::-moz-selection {
              color: #171717;
              background-color: #fcc603;
            }
          }

          textarea {
            flex-grow: 1;
            min-height: 200px;
            max-height: 200px;
            height: 150px;
            line-height: 18px;
            padding: 8px;
            word-spacing: normal;
            color: #171717;
            border-radius: 1px;
            background-color: #d2d2d2;

            &::-moz-selection {
              color: #171717;
              background-color: #fcc603;
            }
          }
        }

        button {
          display: block;
          align-self: center;
          cursor: pointer;
          width: 50%;
          height: auto;
          line-height: 40px;
          margin: 10px 0 2px 0;
          text-align: center;
          font-size: 1.2rem;
          font-weight: bold;
          border-radius: 1px;
          border: 1px solid #fcc603;
          box-shadow: 0 0 0 0 #fcc603;
          transition: 0s;
          -webkit-transition: 0s;
          -moz-transition: 0s;
          -o-transition: 0s;

          &:hover {
            margin: 12px 0 0 2px;
            box-shadow: -2px -2px 0 0 #fcc603;
            transition: 0s;
            -webkit-transition: 0s;
            -moz-transition: 0s;
            -o-transition: 0s;
          }
        }
      }

      span.element-tag {
        display: block;
        position: absolute;
        width: auto;
        height: auto;
        line-height: 14px;
        bottom: -22px;
        right: -4px;
        font-family: "Lexend Deca";
        font-size: 0.9rem;
        color: #fcc603;
        background-color: #fcc603;
      }

      span.element-tag::selection {
        background-color: black;
      }

      span.element-tag::-moz-selection {
        background-color: black;
      }
    }
  }
}

@media screen and (max-width: 799px) {
  section.game {
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    width: auto;
    min-height: 100vh;
    height: auto;
    padding: 15% 20px;
    background-color: rgba(252, 198, 3, 0.3);

    aside.title {
      display: flex;
      flex-flow: column wrap;
      justify-content: flex-start;
      align-items: center;
      position: relative;
      flex-grow: 1;
      width: 100%;
      height: auto;
      padding: 10px;
      padding-bottom: 0;
      background-color: rgba(0, 0, 0, 0.5);
      box-shadow: -4px -4px 0px 0px #fcc603;

      div.tags {
        display: flex;
        flex-flow: column wrap;
        width: auto;
        height: auto;
        padding: 10px 0;

        span.tag {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          width: auto;
          height: auto;
          line-height: 20px;
          margin-bottom: 10px;
          padding: 10px 15px;
          font-size: 0.85rem;
          font-family: "Lexend Deca";
          letter-spacing: 0.06rem;
          border: 1px solid #fcc603;
          box-shadow: -2px -2px 0px 0px #fcc603;

          strong {
            display: block;
            width: auto;
            height: auto;
            margin: 0 0 8px -6px;
            padding: 0 10px 3px 10px;
            font-size: 9px;
            letter-spacing: 0.05rem;
            font-weight: normal;
            border-radius: 25px;
          }

          strong.blue {
            background-color: #2f6ead;

            &::-moz-selection {
              color: #171717;
              background-color: #fcc603;
            }
          }
        }

        span.tag:last-child {
          margin-bottom: 0;
        }

        span.tag::-moz-selection {
          color: #171717;
          background-color: #fcc603;
        }

        span.tag::selection {
          color: #171717;
          background-color: #fcc603;
        }
      }

      span.element-tag {
        display: block;
        position: absolute;
        width: auto;
        height: auto;
        line-height: 14px;
        top: -22px;
        left: -4px;
        font-family: "Lexend Deca";
        font-size: 0.9rem;
        color: #fcc603;
        background-color: #fcc603;
      }

      span.element-tag::selection {
        background-color: black;
      }

      span.element-tag::-moz-selection {
        background-color: black;
      }

      h3 {
        display: block;
        align-self: center;
        width: auto;
        height: auto;
        line-height: 40px;
        font-family: "Turret Road";
        font-size: 1.5rem;
        text-align: center;
        font-weight: bolder;
        letter-spacing: 0.45rem;
      }
    }

    div.desc {
      display: flex;
      position: relative;
      flex-direction: column;
      width: 100%;
      height: auto;
      padding: 10px;
      background-color: rgba(0, 0, 0, 0.5);
      box-shadow: 4px 4px 0px 0px #fcc603;

      form {
        display: flex;
        flex-flow: column wrap;
        flex-grow: 1;
        width: 100%;
        height: auto;

        span {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          width: 100%;
          height: auto;
          margin-bottom: 20px;
          padding: 10px;
          border: 1px solid #fcc603;
          box-shadow: -2px -2px 0px 0px #fcc603;

          label {
            display: block;
            width: 130px;
            height: auto;
            font-size: 1rem;

            &::-moz-selection {
              color: #171717;
              background-color: #fcc603;
            }
          }

          input {
            display: block;
            align-self: center;
            width: auto;
            height: auto;
            line-height: 24px;
            padding: 0 8px;
            word-spacing: normal;
            color: #171717;
            border-radius: 1px;
            background-color: #d2d2d2;

            &::-moz-selection {
              color: #171717;
              background-color: #fcc603;
            }
          }

          textarea {
            flex-grow: 1;
            min-height: 200px;
            max-height: 200px;
            height: 150px;
            line-height: 18px;
            padding: 8px;
            word-spacing: normal;
            color: #171717;
            border-radius: 1px;
            background-color: #d2d2d2;

            &::-moz-selection {
              color: #171717;
              background-color: #fcc603;
            }
          }
        }

        button {
          display: block;
          align-self: center;
          cursor: pointer;
          width: 100%;
          height: auto;
          line-height: 40px;
          margin: 10px 0 2px 0;
          text-align: center;
          font-size: 1.2rem;
          font-weight: bold;
          border-radius: 1px;
          border: 1px solid #fcc603;
          box-shadow: 0 0 0 0 #fcc603;
          transition: 0s;
          -webkit-transition: 0s;
          -moz-transition: 0s;
          -o-transition: 0s;

          &:hover {
            margin: 12px 0 0 2px;
            box-shadow: -2px -2px 0 0 #fcc603;
            transition: 0s;
            -webkit-transition: 0s;
            -moz-transition: 0s;
            -o-transition: 0s;
          }
        }
      }

      span.element-tag {
        display: block;
        position: absolute;
        width: auto;
        height: auto;
        line-height: 14px;
        bottom: -22px;
        right: -4px;
        font-family: "Lexend Deca";
        font-size: 0.9rem;
        color: #fcc603;
        background-color: #fcc603;
      }

      span.element-tag::selection {
        background-color: black;
      }

      span.element-tag::-moz-selection {
        background-color: black;
      }
    }
  }
}
</style>
