<template>
  <section class="skills">
    <div class="title">
      <span class="element-tag">&lt;My Skills Section&gt;</span>
      <h3 class="green-selection">Skills</h3>
      <div class="tags">
        <span class="tag">
          <strong class="blue">Still Learning</strong>
          If you saw this tag on some skills, It means I don't have expert skills in that but I read documents and worked on project with that language/framework/library, and I know what is going on in it and only trying to don't involved so deep.
        </span>
        <span class="tag">
          <strong class="orange">Not Interested</strong>
          If you saw this tag on some skills, It means I worked on projects with that language/framework/library, but I just not interest in that.
        </span>
      </div>
    </div>
    <div class="desc">

      <div>
        <h3>Front-End Skills</h3>
        <div class="bars">
          <skills-capsule color="#ec6231">
            <template #icon><img :src="require('@/assets/icons/html5.svg')" alt="html5-by-icons8"></template>
            <template #name>HTML5</template>
            <template #start-year>2013</template>
          </skills-capsule>
          <skills-capsule color="#2965f1">
            <template #icon><img :src="require('@/assets/icons/css3.svg')" alt="css3-by-icons8"></template>
            <template #name>CSS3</template>
            <template #start-year>2013</template>
          </skills-capsule>
          <skills-capsule color="#f0db4f" text-color="#171717">
            <template #icon><img :src="require('@/assets/icons/javascript.svg')" alt="javascript-by-icons8"></template>
            <template #name>JavaScript</template>
            <template #start-year>2013</template>
          </skills-capsule>
          <skills-capsule color="#3178c6">
            <template #icon><img :src="require('@/assets/icons/typescript.svg')" alt="typescript svg icon"></template>
            <template #name>TypeScript</template>
            <template #start-year>2021</template>
          </skills-capsule>
          <skills-capsule color="#0769ad">
            <template #icon><img :src="require('@/assets/icons/jquery.svg')" alt="javascript-by-icons8"></template>
            <template #name>jQuery</template>
            <template #start-year>2013</template>
          </skills-capsule>
          <skills-capsule color="#CF649A">
            <template #icon><img :src="require('@/assets/icons/sass.svg')" alt="sass-by-icons8"></template>
            <template #name>Sass</template>
            <template #start-year>2017</template>
          </skills-capsule>
          <skills-capsule color="#712cf9">
            <template #icon><img :src="require('@/assets/icons/bootstrap.svg')" alt="bootstrap svg icon"></template>
            <template #name>Bootstrap</template>
            <template #start-year>2017</template>
          </skills-capsule>
          <skills-capsule color="#42b883" text-color="#171717">
            <template #icon><img :src="require('@/assets/icons/vuejs.svg')" alt="vuejs-by-icons8"></template>
            <template #name>VueJs</template>
            <template #start-year>2020</template>
          </skills-capsule>
          <skills-capsule color="#DD0031">
            <template #icon><img :src="require('@/assets/icons/angular.svg')" alt="angular svg icon"></template>
            <template #name>Angular</template>
            <template #start-year>2021</template>
          </skills-capsule>
          <skills-capsule color="#61dbfb" text-color="#171717" :still-learning="true">
            <template #icon><img :src="require('@/assets/icons/react.svg')" alt="react-by-icons8"></template>
            <template #name>React</template>
            <template #start-year>2022</template>
          </skills-capsule>
          <skills-capsule color="#42b883" text-color="#171717">
            <template #icon><img :src="require('@/assets/icons/nuxtjs3.svg')" alt="nuxt logo svg icon"></template>
            <template #name>NuxtJs</template>
            <template #start-year>2022</template>
          </skills-capsule>
        </div>
      </div>

      <div>
        <h3>Back-End Skills</h3>
        <div class="bars">
          <skills-capsule color="#026e00">
            <template #icon><img :src="require('@/assets/icons/nodejs.svg')" alt="nodejs svg icon"></template>
            <template #name>Node.js</template>
            <template #start-year>2020</template>
          </skills-capsule>
          <skills-capsule color="#FF2D20">
            <template #icon><img :src="require('@/assets/icons/nestjs.svg')" alt="nestjs svg icon"></template>
            <template #name>NestJs</template>
            <template #start-year>2022</template>
          </skills-capsule>
          <skills-capsule color="#474A8A">
            <template #icon><img :src="require('@/assets/icons/php.svg')" alt="php-by-icons8"></template>
            <template #name>PHP 5.2 - 8</template>
            <template #start-year>2013</template>
          </skills-capsule>
          <skills-capsule color="#FF2D20">
            <template #icon><img :src="require('@/assets/icons/laravel.svg')" alt="laravel-by-icons8"></template>
            <template #name>Laravel</template>
            <template #start-year>2018</template>
          </skills-capsule>
          <skills-capsule color="#C92735" :not-interest="true">
            <template #icon><img :src="require('@/assets/icons/cakephp.svg')" alt="cakephp-by-icons8"></template>
            <template #name>CakePHP</template>
            <template #start-year>2016</template>
          </skills-capsule>
          <skills-capsule color="#00758F">
            <template #icon><img :src="require('@/assets/icons/mysql.svg')" alt="mysql-by-icons8"></template>
            <template #name>MySQL</template>
            <template #start-year>2013</template>
          </skills-capsule>
          <skills-capsule color="#006064">
            <template #icon><img :src="require('@/assets/icons/mariadb.svg')" alt="mariadb-by-icons8"></template>
            <template #name>MariaDB</template>
            <template #start-year>2016</template>
          </skills-capsule>
          <skills-capsule color="#3FA037">
            <template #icon><img :src="require('@/assets/icons/mongodb.svg')" alt="mongodb-by-icons8"></template>
            <template #name>MongoDB</template>
            <template #start-year>2017</template>
          </skills-capsule>
          <skills-capsule color="#699eca">
            <template #icon><img :src="require('@/assets/icons/postgresql.png')" alt="postgres png icon"></template>
            <template #name>Postgres</template>
            <template #start-year>2020</template>
          </skills-capsule>
          <skills-capsule color="#E83524">
<!--            <template #icon><img :src="require('@/assets/icons/postgresql.png')" alt="postgres png icon"></template>-->
            <template #name>TypeORM</template>
            <template #start-year>2022</template>
          </skills-capsule>
          <skills-capsule color="#306998" :still-learning="true">
            <template #icon><img :src="require('@/assets/icons/python.svg')" alt="python-by-icons8"></template>
            <template #name>Python 3</template>
            <template #start-year>2019</template>
          </skills-capsule>
          <skills-capsule color="#092e20" :still-learning="true">
            <template #icon><img :src="require('@/assets/icons/django.svg')" alt="django-by-icons8"></template>
            <template #name>Django</template>
            <template #start-year>2019</template>
          </skills-capsule>
        </div>
      </div>

      <div>
        <h3>Other Skills</h3>
        <p>
          Here you may see some skills which belongs to front-end or back-end things, but I decide to put these things
          here,
          <br>
          because I think these are regular skills which every web developer should have a low knowledge about it.
          <br>
          of course you know I'm not talking about every skill I listed here, just talking about some.
        </p>
        <div class="bars">
          <skills-capsule color="#1d63ed">
            <template #icon><img :src="require('@/assets/icons/docker.svg')" alt="docker svg icon"></template>
            <template #name>Docker</template>
            <template #start-year>2021</template>
          </skills-capsule>
          <skills-capsule color="#cc5679">
            <template #name>WebSocket</template>
            <template #start-year>2017</template>
          </skills-capsule>
          <skills-capsule color="#cc5679">
            <template #name>Socket.IO</template>
            <template #start-year>2021</template>
          </skills-capsule>
          <skills-capsule color="#cc5679">
            <template #name>PWA</template>
            <template #start-year>2018</template>
          </skills-capsule>
          <skills-capsule color="#cc5679">
            <template #name>REST API</template>
            <template #start-year>2018</template>
          </skills-capsule>
          <skills-capsule color="#cc5679">
            <template #name>AJAX</template>
            <template #start-year>2015</template>
          </skills-capsule>
          <skills-capsule color="#cc5679">
            <template #name>Responsive</template>
            <template #start-year>2015</template>
          </skills-capsule>
          <skills-capsule color="#cc5679">
            <template #name>Trello</template>
            <template #start-year>2015</template>
          </skills-capsule>
          <skills-capsule color="#cc5679">
            <template #name>Git</template>
            <template #start-year>2015</template>
          </skills-capsule>
          <skills-capsule color="#cc5679">
            <template #name>CI/CD</template>
            <template #start-year>2018</template>
          </skills-capsule>
          <skills-capsule color="#cc5679">
            <template #name>Linux</template>
            <template #start-year>2015</template>
          </skills-capsule>
          <skills-capsule color="#cc5679">
            <template #name>Server Manager</template>
            <template #start-year>2017</template>
          </skills-capsule>
          <skills-capsule color="#cc5679">
            <template #name>ChartJS</template>
            <template #start-year>2020</template>
          </skills-capsule>
          <skills-capsule color="#cc5679">
            <template #name>ApexChart</template>
            <template #start-year>2020</template>
          </skills-capsule>
          <skills-capsule color="#cc5679" :still-learning="true">
            <template #name>Phaser</template>
            <template #start-year>2021</template>
          </skills-capsule>
          <skills-capsule color="#cc5679" :not-interest="true">
            <template #name>VB.NET</template>
            <template #start-year>2014</template>
          </skills-capsule>
          <skills-capsule color="#cc5679" :not-interest="true">
            <template #name>LUA</template>
            <template #start-year>2014</template>
          </skills-capsule>
          <skills-capsule color="#cc5679" :not-interest="true">
            <template #name>C++</template>
            <template #start-year>2016</template>
          </skills-capsule>
        </div>
      </div>

      <span class="element-tag">&lt;&#8725;My Skills Section&gt;</span>
    </div>
  </section>
</template>

<script>
import SkillsCapsule from "./SkillsCapsule.vue";

export default {
  name: "IndexSkills",
  components: {
    SkillsCapsule,
  }
}
</script>

<style lang="scss" scoped>
@media screen and (min-width: 1200px) {
  section.skills {
    display: flex;
    justify-content: center;
    flex-grow: 1;
    width: auto;
    min-height: 100vh;
    height: auto;
    padding: 5% 10%;
    background-color: rgba(50, 168, 82, 0.3);

    div.title {
      display: flex;
      position: relative;
      align-self: flex-start;
      flex-direction: column;
      width: 20%;
      height: auto;
      padding: 10px;
      background-color: rgba(0, 0, 0, 0.5);
      box-shadow: -4px -4px 0px 0px #32a852;

      div.tags {
        display: flex;
        flex-flow: row wrap;
        width: auto;
        height: auto;
        padding: 10px 0;

        span.tag {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          width: auto;
          height: auto;
          line-height: 20px;
          margin-bottom: 10px;
          padding: 10px 15px;
          font-size: 0.85rem;
          font-family: 'Lexend Deca';
          letter-spacing: 0.06rem;
          border: 1px solid #32a852;
          box-shadow: -2px -2px 0px 0px #32a852;

          strong {
            display: block;
            width: auto;
            height: auto;
            margin: 0 0 8px -6px;
            padding: 0 10px 3px 10px;
            font-size: 9px;
            letter-spacing: 0.05rem;
            font-weight: normal;
            border-radius: 25px;
          }

          strong.blue {
            background-color: #2f6ead;
          }

          strong.orange {
            background-color: #bf7639;
          }

        }

        span.tag
        span.tag::-moz-selection {
          background-color: #32a852;
        }

        span.tag::selection {
          background-color: #32a852;
        }

      }

      span.element-tag {
        display: block;
        position: absolute;
        width: auto;
        height: auto;
        line-height: 14px;
        top: -22px;
        left: -4px;
        font-family: 'Lexend Deca';
        font-size: 0.9rem;
        color: #32a852;
        background-color: #32a852;
      }

      span.element-tag::selection {
        background-color: black;
      }

      span.element-tag::-moz-selection {
        background-color: black;
      }

      h3 {
        display: block;
        width: 100%;
        height: auto;
        line-height: 40px;
        font-family: 'Turret Road';
        font-size: 1.5rem;
        text-align: center;
        font-weight: bolder;
        letter-spacing: 0.45rem;
      }

      img {
        width: 100%;
        height: auto;
        padding: 10px 0;
        border-radius: 50%;
      }

    }

    div.desc {
      display: flex;
      position: relative;
      flex-direction: column;
      width: calc(80% - 10px);
      height: auto;
      margin-left: 10px;
      padding: 20px;
      background-color: rgba(0, 0, 0, 0.5);
      box-shadow: 4px 4px 0px 0px #32a852;

      > div {
        display: flex;
        flex-flow: column wrap;
        width: auto;
        height: auto;

        h3 {
          display: block;
          width: auto;
          height: auto;
          line-height: 26px;
          padding: 2px 12px 0 12px;
          font-family: 'Turret Road';
          font-size: 1.1rem;
          color: #171717;
          background-color: #32a852;
        }

        > p {
          display: block;
          width: 100%;
          height: auto;
          line-height: 20px;
          padding: 20px 15px 15px 15px;
          font-family: 'Lexend Deca', sans-serif;
          font-size: 0.95rem;
          letter-spacing: 0.05rem;
          background-color: rgba(50, 168, 82, 0.2);
        }

        > div.bars {
          display: flex;
          flex-flow: row wrap;
          width: auto;
          height: auto;
          margin-bottom: 40px;
          padding: 15px 10px 0 10px;
          background-color: rgba(50, 168, 82, 0.2);
        }

      }

      span.element-tag {
        display: block;
        position: absolute;
        width: auto;
        height: auto;
        line-height: 14px;
        bottom: -22px;
        right: -4px;
        font-family: 'Lexend Deca';
        font-size: 0.9rem;
        color: #32a852;
        background-color: #32a852;
      }

      span.element-tag::selection {
        background-color: black;
      }

      span.element-tag::-moz-selection {
        background-color: black;
      }

    }

  }
}

@media screen and (min-width: 800px) and (max-width: 1199px) {
  section.skills {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    width: auto;
    min-height: 100vh;
    height: auto;
    padding: 15% 20px;
    background-color: rgba(50, 168, 82, 0.3);

    div.title {
      display: flex;
      flex-flow: column wrap;
      justify-content: flex-start;
      align-items: center;
      position: relative;
      flex-grow: 1;
      width: auto;
      height: auto;
      padding: 10px;
      background-color: rgba(0, 0, 0, 0.5);
      box-shadow: -4px -4px 0px 0px #32a852;

      div.tags {
        display: flex;
        max-width: 100%;
        width: auto;
        height: auto;
        padding: 10px 0;

        span.tag {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          width: 50%;
          height: auto;
          line-height: 20px;
          margin-right: 10px;
          padding: 10px 15px;
          font-size: 0.85rem;
          font-family: 'Lexend Deca';
          letter-spacing: 0.06rem;
          border: 1px solid #32a852;
          box-shadow: -2px -2px 0px 0px #32a852;

          strong {
            display: block;
            width: auto;
            height: auto;
            margin: 0 0 8px -6px;
            padding: 0 10px 3px 10px;
            font-size: 9px;
            letter-spacing: 0.05rem;
            font-weight: normal;
            border-radius: 25px;
          }

          strong.blue {
            background-color: #2f6ead;
          }

          strong.orange {
            background-color: #bf7639;
          }

        }

        span.tag::-moz-selection {
          background-color: #32a852;
        }

        span.tag::selection {
          background-color: #32a852;
        }

      }

      span.element-tag {
        display: block;
        position: absolute;
        width: auto;
        height: auto;
        line-height: 14px;
        top: -22px;
        left: -4px;
        font-family: 'Lexend Deca';
        font-size: 0.9rem;
        color: #32a852;
        background-color: #32a852;
      }

      span.element-tag::selection {
        background-color: black;
      }

      span.element-tag::-moz-selection {
        background-color: black;
      }

      h3 {
        display: block;
        align-self: center;
        width: auto;
        height: auto;
        line-height: 40px;
        font-family: 'Turret Road';
        font-size: 1.5rem;
        text-align: center;
        font-weight: bolder;
        letter-spacing: 0.45rem;
      }

    }

    div.desc {
      display: flex;
      position: relative;
      flex-direction: column;
      width: 100%;
      height: auto;
      padding: 10px;
      background-color: rgba(0, 0, 0, 0.5);
      box-shadow: 4px 4px 0px 0px #32a852;

      > div {
        display: flex;
        flex-flow: column wrap;
        width: auto;
        height: auto;

        h3 {
          display: block;
          width: auto;
          height: auto;
          line-height: 26px;
          padding: 2px 12px 0 12px;
          font-family: 'Turret Road';
          font-size: 1.1rem;
          color: #171717;
          background-color: #32a852;
        }

        > p {
          display: block;
          width: 100%;
          height: auto;
          line-height: 20px;
          padding: 20px 15px 15px 15px;
          font-family: 'Lexend Deca', sans-serif;
          font-size: 0.95rem;
          letter-spacing: 0.05rem;
          background-color: rgba(50, 168, 82, 0.2);
        }

        > div.bars {
          display: flex;
          flex-flow: row wrap;
          width: auto;
          height: auto;
          margin-bottom: 20px;
          padding: 15px 10px 0 10px;
          background-color: rgba(50, 168, 82, 0.2);
        }

      }

      span.element-tag {
        display: block;
        position: absolute;
        width: auto;
        height: auto;
        line-height: 14px;
        bottom: -22px;
        right: -4px;
        font-family: 'Lexend Deca';
        font-size: 0.9rem;
        color: #32a852;
        background-color: #32a852;
      }

      span.element-tag::selection {
        background-color: black;
      }

      span.element-tag::-moz-selection {
        background-color: black;
      }

    }

  }
}

@media screen and (max-width: 799px) {
  section.skills {
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    width: auto;
    min-height: 100vh;
    height: auto;
    padding: 15% 20px;
    background-color: rgba(50, 168, 82, 0.3);

    div.title {
      display: flex;
      flex-flow: column wrap;
      justify-content: flex-start;
      align-items: center;
      position: relative;
      flex-grow: 1;
      width: auto;
      height: auto;
      padding: 10px;
      background-color: rgba(0, 0, 0, 0.5);
      box-shadow: -4px -4px 0px 0px #32a852;

      div.tags {
        display: flex;
        flex-flow: column wrap;
        width: auto;
        height: auto;
        padding: 10px 0;

        span.tag {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          width: auto;
          height: auto;
          line-height: 20px;
          margin-bottom: 10px;
          padding: 10px 15px;
          font-size: 0.85rem;
          font-family: 'Lexend Deca';
          letter-spacing: 0.06rem;
          border: 1px solid #32a852;
          box-shadow: -2px -2px 0px 0px #32a852;

          strong {
            display: block;
            width: auto;
            height: auto;
            margin: 0 0 8px -6px;
            padding: 0 10px 3px 10px;
            font-size: 9px;
            letter-spacing: 0.05rem;
            font-weight: normal;
            border-radius: 25px;
          }

          strong.blue {
            background-color: #2f6ead;
          }

          strong.orange {
            background-color: #bf7639;
          }

        }

        span.tag:last-child {
          margin-bottom: 0;
        }

        span.tag::-moz-selection {
          background-color: #32a852;
        }

        span.tag::selection {
          background-color: #32a852;
        }

      }

      span.element-tag {
        display: block;
        position: absolute;
        width: auto;
        height: auto;
        line-height: 14px;
        top: -22px;
        left: -4px;
        font-family: 'Lexend Deca';
        font-size: 0.9rem;
        color: #32a852;
        background-color: #32a852;
      }

      span.element-tag::selection {
        background-color: black;
      }

      span.element-tag::-moz-selection {
        background-color: black;
      }

      h3 {
        display: block;
        align-self: center;
        width: auto;
        height: auto;
        line-height: 40px;
        margin-left: -10px;
        font-family: 'Turret Road';
        font-size: 1.5rem;
        text-align: center;
        font-weight: bolder;
        letter-spacing: 0.45rem;
      }

    }

    div.desc {
      display: flex;
      position: relative;
      flex-direction: column;
      width: 100%;
      height: auto;
      padding: 10px;
      background-color: rgba(0, 0, 0, 0.5);
      box-shadow: 4px 4px 0px 0px #32a852;

      > div {
        display: flex;
        flex-flow: column wrap;
        width: auto;
        height: auto;

        h3 {
          display: block;
          width: auto;
          height: auto;
          line-height: 26px;
          padding: 2px 12px 0 12px;
          font-family: 'Turret Road';
          font-size: 1.1rem;
          color: #171717;
          background-color: #32a852;
        }

        > p {
          display: block;
          width: 100%;
          height: auto;
          line-height: 20px;
          padding: 20px 15px 15px 15px;
          font-family: 'Lexend Deca', sans-serif;
          font-size: 0.95rem;
          letter-spacing: 0.05rem;
          background-color: rgba(50, 168, 82, 0.2);
        }

        > div.bars {
          display: flex;
          flex-flow: row wrap;
          width: auto;
          height: auto;
          margin-bottom: 20px;
          padding: 15px 10px 0 10px;
          background-color: rgba(50, 168, 82, 0.2);
        }

      }

      span.element-tag {
        display: block;
        position: absolute;
        width: auto;
        height: auto;
        line-height: 14px;
        bottom: -22px;
        right: -4px;
        font-family: 'Lexend Deca';
        font-size: 0.9rem;
        color: #32a852;
        background-color: #32a852;
      }

      span.element-tag::selection {
        background-color: black;
      }

      span.element-tag::-moz-selection {
        background-color: black;
      }

    }

  }
}
</style>