<template>

    <div class="has-bg preview-image">
      <div class="full-img">
        <img :src="projectData.img" alt="preview">
      </div>
      <span>
          Scroll to see more
        </span>
    </div>

    <div class="has-bg">
      <h3>Description</h3>
      <p v-text="projectData.desc"></p>
    </div>

    <div class="has-bg">
      <h3>Used Skills</h3>
      <div class="bars">

        <skills-capsule v-if="projectData.techs.includes('html5')" :print-year="false" color="#ec6231">
          <template #icon><img :src="require('@/assets/icons/html5.svg')" alt="html5-by-icons8"></template>
          <template #name>HTML5</template>
        </skills-capsule>
        <skills-capsule v-if="projectData.techs.includes('css3')" :print-year="false" color="#2965f1">
          <template #icon><img :src="require('@/assets/icons/css3.svg')" alt="css3-by-icons8"></template>
          <template #name>CSS3</template>
        </skills-capsule>
        <skills-capsule v-if="projectData.techs.includes('javascript')" :print-year="false" color="#f0db4f" text-color="#171717">
          <template #icon><img :src="require('@/assets/icons/javascript.svg')" alt="javascript-by-icons8"></template>
          <template #name>JavaScript</template>
        </skills-capsule>
        <skills-capsule v-if="projectData.techs.includes('typescript')" :print-year="false" color="#3178c6">
          <template #icon><img :src="require('@/assets/icons/typescript.svg')" alt="typescript svg icon"></template>
          <template #name>TypeScript</template>
        </skills-capsule>
        <skills-capsule v-if="projectData.techs.includes('jquery')" :print-year="false" color="#0769ad">
          <template #icon><img :src="require('@/assets/icons/jquery.svg')" alt="javascript-by-icons8"></template>
          <template #name>jQuery</template>
        </skills-capsule>
        <skills-capsule v-if="projectData.techs.includes('vuejs')" :print-year="false" color="#42b883" text-color="#171717">
          <template #icon><img :src="require('@/assets/icons/vuejs.svg')" alt="vuejs-by-icons8"></template>
          <template #name>VueJs</template>
        </skills-capsule>
        <skills-capsule v-if="projectData.techs.includes('nuxtjs')" :print-year="false" color="#42b883" text-color="#171717">
          <template #icon><img :src="require('@/assets/icons/nuxtjs3.svg')" alt="nuxtjs svg icon"></template>
          <template #name>NuxtJs</template>
        </skills-capsule>
        <skills-capsule v-if="projectData.techs.includes('angular')" :print-year="false" color="#DD0031">
          <template #icon><img :src="require('@/assets/icons/angular.svg')" alt="angularjs svg icon"></template>
          <template #name>AngularJs</template>
        </skills-capsule>
        <skills-capsule v-if="projectData.techs.includes('sass')" :print-year="false" color="#712cf9">
          <template #icon><img :src="require('@/assets/icons/bootstrap.svg')" alt="bootstrap svg icon"></template>
          <template #name>Bootstrap</template>
        </skills-capsule>
        <skills-capsule v-if="projectData.techs.includes('sass')" :print-year="false" color="#CF649A">
          <template #icon><img :src="require('@/assets/icons/sass.svg')" alt="sass-by-icons8"></template>
          <template #name>Sass</template>
        </skills-capsule>
        <skills-capsule v-if="projectData.techs.includes('react')" :print-year="false" color="#61dbfb" text-color="#171717">
          <template #icon><img :src="require('@/assets/icons/react.svg')" alt="react-by-icons8"></template>
          <template #name>React</template>
        </skills-capsule>

        <skills-capsule v-if="projectData.techs.includes('php5')" :print-year="false" color="#474A8A">
          <template #icon><img :src="require('@/assets/icons/php.svg')" alt="php-by-icons8"></template>
          <template #name>PHP 5</template>
        </skills-capsule>
        <skills-capsule v-if="projectData.techs.includes('php7')" :print-year="false" color="#474A8A">
          <template #icon><img :src="require('@/assets/icons/php.svg')" alt="php-by-icons8"></template>
          <template #name>PHP 7</template>
        </skills-capsule>
        <skills-capsule v-if="projectData.techs.includes('php8')" :print-year="false" color="#474A8A">
          <template #icon><img :src="require('@/assets/icons/php.svg')" alt="php-by-icons8"></template>
          <template #name>PHP 8</template>
        </skills-capsule>
        <skills-capsule v-if="projectData.techs.includes('nodejs')" :print-year="false" color="#026e00">
          <template #icon><img :src="require('@/assets/icons/nodejs.svg')" alt="nodejs svg icon"></template>
          <template #name>Node.Js</template>
        </skills-capsule>
        <skills-capsule v-if="projectData.techs.includes('nestjs')" :print-year="false" color="#FF2D20">
          <template #icon><img :src="require('@/assets/icons/nestjs.svg')" alt="nestjs svg icon"></template>
          <template #name>NestJs</template>
        </skills-capsule>
        <skills-capsule v-if="projectData.techs.includes('mysql')" :print-year="false" color="#00758F">
          <template #icon><img :src="require('@/assets/icons/mysql.svg')" alt="mysql-by-icons8"></template>
          <template #name>MySQL</template>
        </skills-capsule>
        <skills-capsule v-if="projectData.techs.includes('postgresql')" :print-year="false" color="#699eca">
          <template #icon><img :src="require('@/assets/icons/postgresql.png')" alt="postgres png icon"></template>
          <template #name>Postgres</template>
        </skills-capsule>
        <skills-capsule v-if="projectData.techs.includes('mariadb')" :print-year="false" color="#006064">
          <template #icon><img :src="require('@/assets/icons/mariadb.svg')" alt="mariadb-by-icons8"></template>
          <template #name>MariaDB</template>
        </skills-capsule>
        <skills-capsule v-if="projectData.techs.includes('mongodb')" :print-year="false" color="#3FA037">
          <template #icon><img :src="require('@/assets/icons/mongodb.svg')" alt="mongodb-by-icons8"></template>
          <template #name>MongoDB</template>
        </skills-capsule>
        <skills-capsule v-if="projectData.techs.includes('python3')" :print-year="false" color="#306998">
          <template #icon><img :src="require('@/assets/icons/python.svg')" alt="python-by-icons8"></template>
          <template #name>Python 3</template>
        </skills-capsule>
        <skills-capsule v-if="projectData.techs.includes('django')" :print-year="false" color="#092e20">
          <template #icon><img :src="require('@/assets/icons/django.svg')" alt="django-by-icons8"></template>
          <template #name>Django</template>
        </skills-capsule>
        <skills-capsule v-if="projectData.techs.includes('cakephp')" :print-year="false" color="#C92735">
          <template #icon><img :src="require('@/assets/icons/cakephp.svg')" alt="cakephp-by-icons8"></template>
          <template #name>CakePHP</template>
        </skills-capsule>

        <skills-capsule v-if="projectData.techs.includes('docker')" :print-year="false" color="#1d63ed">
          <template #icon><img :src="require('@/assets/icons/docker.svg')" alt="docker svg icon"></template>
          <template #name>Docker</template>
        </skills-capsule>
        <skills-capsule v-if="projectData.techs.includes('websocket')" :print-year="false" color="#cc5679">
          <template #name>WebSocket</template>
        </skills-capsule>
        <skills-capsule v-if="projectData.techs.includes('socketio')" :print-year="false" color="#cc5679">
          <template #name>Socket.IO</template>
        </skills-capsule>
        <skills-capsule v-if="projectData.techs.includes('pwa')" :print-year="false" color="#cc5679">
          <template #name>PWA</template>
        </skills-capsule>
        <skills-capsule v-if="projectData.techs.includes('restapi')" :print-year="false" color="#cc5679">
          <template #name>REST API</template>
        </skills-capsule>
        <skills-capsule v-if="projectData.techs.includes('ajax')" :print-year="false" color="#cc5679">
          <template #name>AJAX</template>
        </skills-capsule>
        <skills-capsule v-if="projectData.techs.includes('responsive')" :print-year="false" color="#cc5679">
          <template #name>Responsive</template>
        </skills-capsule>
        <skills-capsule v-if="projectData.techs.includes('trello')" :print-year="false" color="#cc5679">
          <template #name>Trello</template>
        </skills-capsule>
        <skills-capsule v-if="projectData.techs.includes('git')" :print-year="false" color="#cc5679">
          <template #name>Git</template>
        </skills-capsule>
        <skills-capsule v-if="projectData.techs.includes('cicd')" :print-year="false" color="#cc5679">
          <template #name>CI/CD</template>
        </skills-capsule>
        <skills-capsule v-if="projectData.techs.includes('linux')" :print-year="false" color="#cc5679">
          <template #name>Linux</template>
        </skills-capsule>
        <skills-capsule v-if="projectData.techs.includes('servermanager')" :print-year="false" color="#cc5679">
          <template #name>Server Manager</template>
        </skills-capsule>
        <skills-capsule v-if="projectData.techs.includes('chartjs')" :print-year="false" color="#cc5679">
          <template #name>ChartJS</template>
        </skills-capsule>
        <skills-capsule v-if="projectData.techs.includes('phaser')" :print-year="false" color="#cc5679">
          <template #name>Phaser</template>
        </skills-capsule>
        <skills-capsule v-if="projectData.techs.includes('vbnet')" :print-year="false" color="#cc5679">
          <template #name>VB.NET</template>
        </skills-capsule>
        <skills-capsule v-if="projectData.techs.includes('lua')" :print-year="false" color="#cc5679">
          <template #name>LUA</template>
        </skills-capsule>
        <skills-capsule v-if="projectData.techs.includes('cpp')" :print-year="false" color="#cc5679">
          <template #name>C++</template>
        </skills-capsule>

      </div>
    </div>

</template>

<script>
import skillsCapsule from "./SkillsCapsule";

export default {
  name: "ProjectTemplate",
  components: {
    skillsCapsule
  },
  props: {
    projectData: Object
  }
}
</script>

<style lang="scss" scoped>
div.preview-image {
  display: block;
  position: relative;
  width: auto;

  div.full-img {
    display: block;
    overflow-y: auto;
    width: 100%;
    max-height: 547px;
    height: 100%;

    img {
      width: 100%;
    }

  }

  > span {
    display: block;
    cursor: default;
    position: absolute;
    width: auto;
    height: auto;
    line-height: 26px;
    padding: 0 10px;
    bottom: 10px;
    right: 0;
    border-radius: 1px 0 0 1px;
    font-family: 'Lexend Deca', sans-serif;
    background-color: #bf5261;
  }

}

div.has-bg {
  display: flex;
  flex-flow: column wrap;
  width: auto;
  height: auto;
  margin-bottom: 20px;

  h3 {
    display: block;
    width: auto;
    height: auto;
    line-height: 26px;
    padding: 2px 12px 0 12px;
    font-family: 'Turret Road';
    font-size: 1.1rem;
    color: #171717;
    background-color: #bf5261;
  }

  > p {
    display: block;
    width: 100%;
    height: auto;
    line-height: 20px;
    padding: 15px;
    font-family: 'Lexend Deca', sans-serif;
    font-size: 0.95rem;
    letter-spacing: 0.05rem;
    background-color: rgba(191, 82, 97, 0.2);
  }

  > div.bars {
    display: flex;
    flex-flow: row wrap;
    width: auto;
    height: auto;
    margin-bottom: 40px;
    padding: 15px 10px 0 10px;
    background-color: rgba(191, 82, 97, 0.2);
  }

}

> div:last-child {
  margin-bottom: 0;
}

span.element-tag {
  display: block;
  position: absolute;
  width: auto;
  height: auto;
  line-height: 14px;
  bottom: -22px;
  right: -4px;
  font-family: 'Lexend Deca';
  font-size: 0.9rem;
  color: #bf5261;
  background-color: #bf5261;
}

span.element-tag::selection {
  background-color: black;
}

span.element-tag::-moz-selection {
  background-color: black;
}
</style>